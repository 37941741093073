/* Global Styles */
body {
  background-color: #f4f7f9;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  margin: 0;
  padding: 0;
  color: #333;
}

.headerline {
  height: 4px;
  background: linear-gradient(90deg, #c90d0b 0%, #f45a48 100%);
  margin-bottom: 2%;
}

.footerline {
  height: 5px;
  background: linear-gradient(90deg, #0a6aa1 0%, #1e90ff 100%);
  margin-top: 2%;
}

.color-red {
  color: #c90d0b;
}

.color-green {
  color: #1a9f16;
}

.color-blue {
  color: #2699fb;
}

/* Container */
.container {
  max-width: 80%;
  margin: 30px auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  border: 1px solid #ddd;
}

/* Images */
img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto 20px;
}

/* Headings */
h3 {
  font-size: 22px;
  margin-bottom: 15px;
  color: #333;
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;
}

h4 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #444;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
}

/* Tables */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

table td,
table th {
  padding: 15px;
  border: 1px solid #ddd;
}

table th {
  background-color: #f7f7f7;
  font-weight: 600;
  text-align: left;
  color: #666;
}

table td {
  text-align: left;
  color: #555;
}

/* Responsive Design */
@media (max-width: 768px) {
  .container {
      padding: 20px;
  }

  h3 {
      font-size: 20px;
  }

  h4 {
      font-size: 16px;
  }
}

/* Links and Buttons */
a {
  color: #2699fb;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #1e90ff;
  text-decoration: underline;
}

.btn {
  background-color: #1a9f16;
  color: white;
  padding: 12px 25px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn:hover {
  background-color: #159b13;
  transform: scale(1.02);
}

/* Print Styles */
@media print {
  .container {
      box-shadow: none;
      border: none;
  }

  .headerline, .footerline {
      display: none;
  }
}
